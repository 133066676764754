* {
  padding: 0;
  margin: 0;
}
.print-wrapper {
  width: 90%;
  margin: 0 auto;
}
.header-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.header-wrapper div.item {
}
.header-wrapper div.item h1 {
  text-align: center;
  font-size: 18px;
  line-height: 31px;
  font-weight: bold;
}
.header-wrapper div#middle img {
  display: block;
  margin: 0 auto;
}

.number-date-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 7px 0 12px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-top: -10px;
}

.body-wrapper table {
  width: 100%;
  border-collapse: collapse;
  border: 3px solid #000 !important;
  margin-top: -20px;
}
.body-wrapper table,
.body-wrapper td {
  border: 1px solid #000;
  padding: 19px 15px;
  font-size: 15px;
  font-weight: 600;
}

.footer-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: -30px;
}
.footer-wrapper div.item p {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 10px;
}
p.note {
  padding: 41px 14px;
  font-size: 15px;
  line-height: 20px;
  margin-top: -30px;
}

@media print {
  .print-wrapper {
    width: 100%;
  }
}
